@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import "tailwindcss/utilities";


/* Addons */
html {
  scroll-behavior: smooth;
}
/* waves */
.ocean {
    height: 80px; 
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
  }
  
  .wave {
    background: url("../../../public/front/assets/images/waves.svg");
    position: absolute;
    width: 200%;
    height: 100%;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
  }
  
  .wave:nth-of-type(2) {
    bottom: 0;
    animation: wave 18s linear reverse infinite;
    opacity: 0.5;
  }
  
  .wave:nth-of-type(3) {
    bottom: 0;
    animation: wave 20s -1s linear infinite;
    opacity: 0.5;
  }
  
  @keyframes wave {
      0% {transform: translateX(0);}
      50% {transform: translateX(-25%);}
      100% {transform: translateX(-50%);}
  }
  
  .btn {
    @apply inline-flex
    items-center
    justify-center
    py-2
    px-6
    rounded
    text-base
    font-medium
    transition
    duration-300
    ease-in-out;
  }
  
  .btn-lime {
    @apply  text-white
    bg-lime-700
    hover:bg-opacity-90 hover:shadow-lg
  }
  
  
  /* layouts */
  
  .sticky {
    @apply fixed z-[9999] transition bg-lime-800 bg-opacity-95;
    backdrop-filter: blur(5px);
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  }
  
  .sticky .navbar-logo {
    @apply py-2;
  }
  
  
  .sticky #navbarCollapse li a {
    @apply text-white hover:opacity-100 hover:text-yellow-600 font-semibold;
  }
  #navbarCollapse li .ud-menu-scroll.active {
    @apply text-yellow-600;
  }
  .sticky #navbarCollapse li .ud-menu-scroll.active {
    @apply text-yellow-600 ;
  }
  .sticky .loginBtn {
    @apply text-dark hover:text-lime-800 hover:opacity-100;
  }
  
  .sticky .signUpBtn {
    @apply text-white bg-lime-800 hover:bg-dark hover:text-white;
  }
  
  .navbarTogglerActive > span:nth-child(1) {
    @apply transform rotate-45 top-[7px];
  }
  .navbarTogglerActive > span:nth-child(2) {
    @apply opacity-0;
  }
  .navbarTogglerActive > span:nth-child(3) {
    @apply top-[-8px] rotate-[135deg];
  }
  
  /* logo animation */
  
  .zoom-in-out {
    animation: zoom-in-out 5s ease-out infinite;
  }
  
  @keyframes zoom-in-out {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.050, 1.050);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .ball {
    margin-top: 50px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: cornflowerblue;
    border: 2px solid #999;
    animation: bounce 5s infinite alternate;
    -webkit-animation: bounce 5s infinite alternate;
  }
  @keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  @-webkit-keyframes bounce {
    from {
      transform: translateY(0px);
    }
    to {
      transform: translateY(-15px);
    }
  }
  
  
  /* Post */
  
  .single-width {
    max-width: 90ch !important;
  }